import React from 'react';

import GoogleMapReact from 'google-map-react';

import moment from 'moment'
import 'moment/locale/pt-br';

import DogMarker from './dogMarker';

function LocationCard({loading, userData}) {
  moment.locale('pt-br');

  if(loading) {
    return (
      <div class="w-1/2 mt-5 pl-2.5">
        <div class="bg-white shadow sm:rounded-lg p-5">
          <div class="loader">Loading...</div>
        </div>
      </div>
    )
  }

  return (
    <div class="w-full mt-5 pl-2.5">
      <div class="bg-white shadow rounded-lg overflow-hidden sm:rounded-lg h-96">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBtReVuSWi472WtdfigQwvj9xy9Nr7Qrqk" }}
          defaultCenter={{lat:userData.last_location.lat, lng:userData.last_location.lng}}
          defaultZoom={16}
        >
          <DogMarker
            lat={userData.last_location.lat}
            lng={userData.last_location.lng}
            dog={userData.pet}
          />
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default LocationCard;
