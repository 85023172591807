import React from 'react';

function Header({title}) {
  return (
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-gray-500">
          {title}
        </h1>
      </div>
    </header>
  );
}

export default Header;
