import React from 'react';

function DogMarker({dog}) {
  return (
    <div class="h-12 w-12 border-4 rounded-full border-yellow-600 border-solid">
      <img class="h-10 w-10 rounded-full" src={dog.avatar.url} alt=""/>
    </div>
  )
}

export default DogMarker;
