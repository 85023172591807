import React from 'react';
import moment from 'moment'

function BatteryDisplay({collar}) {
  const batteryColor = ()=> {
    if(collar.battery > 60) {
      return 'green'
    }
    else if(collar.battery > 15) {
      return 'yellow'
    }
    else {
      return 'red'
    }
  }

  const renderBattery = () => {
    const startTime = moment(collar.last_synced_at);
    const duration = moment.duration(moment().diff(startTime));
    const hours = duration.asHours();

    if(collar.battery <= 10 || (collar.battery <= 20 && hours > 24)) {
      return (
        <div class="relative bg-gray-300 h-4 mt-3 rounded-md shadow-sm text-xs leading-4 font-medium text-gray-800">
        <div class="absolute text-center w-full">
            0%
        </div>
      </div>
      )
    } else {
      return (
        <div class="relative bg-gray-300 h-4 mt-3 rounded-md shadow-sm text-xs leading-4 font-medium text-gray-800">
          <div class="absolute text-center w-full">
            {collar.battery}%
          </div>
          <div style={{
              width: `${collar.battery}%`
            }} class={`bg-${batteryColor()}-500 h-4 rounded-md shadow-sm text-xs leading-4`}
          >
          </div>
        </div>
      )
    }
  }

  return (
    <div class="text-xs w-8">
      {renderBattery()}
    </div>
  );
}

export default BatteryDisplay;
