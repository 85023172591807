import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';

import useToken from './components/useToken';
import MenuLink from "./components/menuLink";

import Login from './pages/login';
import Pets from "./pages/pets";

function App() {
  const [ currentPage, setCurrentPage ] = useState('dashboard');
  const [ openMenu, setOpenMenu ] = useState(false);
  const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <Router>
      <div>
        <nav class="bg-gray-500">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <img class="h-8 w-8" src="/logo.png" alt="urdog"/>
                </div>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-4">
                    <MenuLink
                      link='/'
                      text='Pets'
                      active={currentPage === 'pets'}
                      onclick={() => setCurrentPage('pets')}
                    />
                  </div>
                </div>
              </div>
              <div class="-mr-2 flex md:hidden">
                <button onClick={() => setOpenMenu(!openMenu)} class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span class="sr-only">Open main menu</span>
                  <svg class={`${openMenu ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  <svg class={`${openMenu ? 'block' : 'hidden '} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div class={`${openMenu ? 'block' : 'hidden'} md:hidden`}>
            <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Pets</Link>
            </div>
          </div>
        </nav>

        <Switch>
          <Route path="/">
            <Pets updatePage={setCurrentPage}/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
