import React from 'react';
import {
  Link
} from "react-router-dom";

function MenuLink({link, text, onclick, active=false}) {
  return (
    <Link
      to={link}
      onClick={onclick}
      class={`${(active) ? 'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium' : 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'}`}
    >
      {text}
    </Link>
  );
}

export default MenuLink;
