import React, { useState } from 'react';
import { authPostRequest, URL } from "../helpers/API";
function SendNotificationForm({loading, userData}) {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if(title && message) {
      setSuccess(true);
      authPostRequest(
        `${URL}/admin/notifications`,
        {
          user_id: userData.user.id,
          title: title,
          message: message
        }
      ).then((_) => {
        setTimeout(() => {
          setSuccess(false);
        }, 1000)
      })
    } else {
      alert("Faltam dados.");
    }
  }


  if(loading) {
    return (
      <div class="w-1/2 mt-5 pl-5">
        <div class="bg-white shadow sm:rounded-lg p-5">
          <div class="loader">Loading...</div>
        </div>
      </div>
    )
  }

  return (
    <div class="w-1/2 mt-5 pl-2.5">
      <div
        class={`${(success) ? 'success items-center bg-green-500 animate-pulse' : ''} bg-white shadow rounded-lg overflow-hidden sm:rounded-lg p-5 flex flex-col justify-center`}
      >
        {(() => {
          if(success) {
            return (
              <div class="rounded-full border-4 border-white p-5 absolute">
                <svg fill="white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
              </div>
            )
          }
        })()}
        <h2 class="font-medium border-b-2 pb-2">Enviar notificação para usuário</h2>
        <form onSubmit={handleSubmit} class="mt-5 flex items-end" action="#">
          <div class="w-full pr-2.5">
            <label for="title" class="block mr-4 text-sm font-medium text-gray-700">Título:</label>
            <input
              type="text"
              name="title"
              id="title"
              onChange={(event) => setTitle(event.target.value)}
              value={title}
              class="w-full mt-2 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
            />
          </div>
          <div class="w-full pl-2.5">
            <label for="message" class="block mr-4 text-sm font-medium text-gray-700">Mensagem:</label>
            <input
              type="text"
              name="message"
              id="message"
              onChange={(event) => setMessage(event.target.value)}
              value={message}
              class="w-full mt-2 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
            />
          </div>
          <div>
            <button type="submit" class="ml-5 bg-yellow-500 py-2 px-3 border border-yellow-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SendNotificationForm;
