import React from 'react';

import moment from 'moment'
import 'moment/locale/pt-br';
import BatteryDisplay from './batteryDisplay';
import { URL, authDeleteRequest, authPostRequest } from '../helpers/API';


function UserCard({loading, userData, loadData}) {
  moment.locale('pt-br');

  // const deleteUser = () => {
  //   authDeleteRequest(`${URL}/users/${userData.id}`)
  //   .then((_) => {
  //     window.location.href = '/';
  //   })
  // }

  const assignMe = () => {
    authPostRequest(
      `${URL}/admin/users/127/assign_user`,
      { pet_id: userData.pet.id }
    )
  }

  if(loading) {
    return (
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="loader">Loading...</div>
      </div>
    )
  }

  return (
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6 relative flex items-center w-full">
        <div class="flex-shrink-0 h-14 w-14">
          <img class="h-14 w-14 rounded-full" src={userData.pet.avatar.url} alt=""/>
        </div>
        <div class='pl-5'>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {userData.pet.name}
          </h3>
          <div class="text-xs text-gray-500 mt-1">
            Dados atualizados - {moment().format('DD/MM/YY - HH:mm:ss')}
          </div>
          <button
            onClick={loadData}
           class="bg-gray-600 my-2 rounded w-20 border-2 border-gray-400 h-7 text-white text-sm"
          >
            reload
          </button>
        </div>
        <div class="flex flex-col justify-end absolute right-8">
          {(userData.collar) ? <BatteryDisplay collar={userData.collar}/> : ''}
          <button 
            onClick={assignMe}
            type="submit"
            class="ml-5 bg-red-300 py-1 px-3 border border-red-600 rounded-md shadow-sm text-sm leading-4 font-medium text-black hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
          >
            Me faça dono
          </button>
        </div>
      </div>
      <div class="border-t border-gray-200 flex">
        <div class="w-1/2 border-r-2">
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Nome do dog
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {userData.pet.name}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Idade do dog
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {userData.pet.age} meses
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Raça do dog
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {userData.pet.breed}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Peso do dog
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {userData.pet.weight} Kgs
              </dd>
            </div>
          </dl>
        </div>
        <div class="w-1/2 border-r-2">
          <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                ID da coleira
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? userData.collar.id : ''}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Modelo
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? userData.collar.model : ''}
              </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Firmware
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? userData.collar.firmware_version : ''}
              </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                BLE MAC
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? userData.collar.identifier : ''}
              </dd>
            </div>
          </dl>
        </div>
        <div class="w-1/2 border-r-2 flex flex-col justify-center items-center">
            <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <div class="text-basefont-medium text-gray-500">
                Último Sync da Coleira
              </div>
              <div class="mt-1 text-lg text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? moment(userData.collar.last_synced_at).fromNow() : ''}
                <br/>
                <span class="text-sm">
                  {(userData.collar) ? moment(userData.collar.last_synced_at).format('DD/MM/YY - HH:mm:ss') : ''}
                </span>
              </div>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <div class="text-base font-medium text-gray-500">
                Primeiro Sync da Coleira
              </div>
              <div class="mt-1 text-lg text-gray-900 sm:mt-0 sm:col-span-2">
                {(userData.collar) ? moment(userData.collar.first_synced_at).fromNow() : ''}
                <br/>
                <span class="text-sm">
                  {(userData.collar) ? moment(userData.collar.first_synced_at).format('DD/MM/YY - HH:mm:ss') : ''}
                </span>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default UserCard;
