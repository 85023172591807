import React from 'react';

function BigCard(props) {
  return (
    <main style={{height: "100vh"}}>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 flex flex-wrap">
        {props.children}
      </div>
    </main>
  );
}

export default BigCard;
