import React, { useState } from 'react';
import { authPostRequest, URL } from "../helpers/API";
function SendDataNotificationForm({loading, userData}) {
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    authPostRequest(
      `${URL}/admin/data_notifications`,
      {
        user_id: userData.user.id
      }
    ).then((res) => {
      if(res && res.status !== 200){
        setErrorMessage(res.error);
        setTimeout(() => {
          setErrorMessage(false);
        }, 3000)
      } else {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 1000)
      }
    })
  }

  if(loading) {
    return (
      <div class="w-1/2 mt-5 pl-5">
        <div class="bg-white shadow sm:rounded-lg p-5">
          <div class="loader">Loading...</div>
        </div>
      </div>
    )
  }

  return (
    <div class="w-1/2 mt-5 pr-2.5">
      <div
        class={`${(success) ? 'success items-center bg-green-500 animate-pulse' : ''} bg-white shadow rounded-lg overflow-hidden sm:rounded-lg p-5 flex flex-col justify-center`}
      >
        {(() => {
          if(success) {
            return (
              <div class="rounded-full border-4 border-white p-5 absolute">
                <svg fill="white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
              </div>
            )
          }
        })()}
        <h2 class="font-medium border-b-2 pb-2 mb-5">Acordar Aplicativo</h2>
        <p class='text-sm text-red-400 font-bold'>
          {(errorMessage) ? `${errorMessage}, tente novamente mais tarde` : ''}
        </p>
        <form onSubmit={handleSubmit} class="flex items-end" action="#">
          <button type="submit" class="w-full bg-yellow-500 py-2 px-3 border border-yellow-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            Acordar
          </button>
        </form>
      </div>
    </div>
  )
}

export default SendDataNotificationForm;
