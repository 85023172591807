import CloudWatchLogs from "aws-sdk/clients/cloudwatchlogs";

const zone = 'sa-east-1';
const groupName = 'mobile-apps';
const streamName = 'mobile-app-logs';
const client_key = 'AKIAJ47ECB77ARQBWPTQ';
const client_secret = '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';

const client = new CloudWatchLogs({
  accessKeyId:client_key,
  secretAccessKey: client_secret,
  region: zone,
});

const logsForId = (id, cb) => {
  var params = {
    logGroupName: groupName,
    filterPattern: `User ID ${id}`,
    interleaved: false,
    logStreamNames: [streamName],
  };

  client.filterLogEvents(params, cb);
}

export {logsForId}