import React, { useState } from 'react';
import moment from 'moment'
import { logsForId } from '../helpers/cloudwatch'
import 'moment/locale/pt-br';

function LogsCard({loading, userData}) {
  moment.locale('pt-br');
  const [logs, setLogs] = useState()
  const [once, setOnce] = useState()

  if(loading) {
    return (
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="loader">Loading...</div>
      </div>
    )
  }

  if(!logs){
    if(!once){
      setOnce(true);
      logsForId(userData?.user?.id, (err, data) => {
        if(!err){
          setLogs(data.events.reverse());
        }
      })
    }
  }

  const renderLogs = () => {
    if(!logs) return <></>;
    return logs.map((log) => <p><b>{moment(log.timestamp).format("DD/MM - HH:mm:ss")}</b> {log.message}</p>);
  }

  return (
    <div class="w-full bg-white shadow overflow-hidden sm:rounded-lg mt-5">
      <div class="px-4 py-5 sm:px-6 relative flex flex-col w-full">
        <h2 class="font-medium border-b-2 pb-2 mb-5">Logs do Aplicativo</h2>
        <div class="w-full">
          {renderLogs()}
        </div>
      </div>
    </div>
  )
}

export default LogsCard;
