const storeData = async (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

const retrieveData = async (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null && value !== "null") {
      return JSON.parse(value)
    }
  } catch (error) {
    console.log(error);
  }
};

const clear = async () => {
  localStorage.clear();
}

export { storeData, retrieveData, clear }