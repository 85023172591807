import { retrieveData, clear } from './store'

const URL = "https://api.urdog.com.br"
// const URL = "http://localhost:3000"
const loginURL = `${URL}/admin_token`

const login = (username, password) => {
  return request(loginURL, {
    auth: {
      email: username,
      password: password
    }
  })
}

const request = (url, body=false, method='POST') => {
  const options = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  if(body){
    options.body = JSON.stringify(body)
  }
  return fetch(url, options)
  .then((response) => {
    if([200,201].includes(response.status)){
      return response.json()
    }
    else if([404].includes(response.status)){
      clear();
      window.location.href = '/';
      throw new Error("Credenciais não são válidas");
    }
    else {
      throw new Error('Error');
    }
  })
}

const authPostRequest = async (url, body={}, root=false, file=false, filename=false) => {
  const token = await retrieveData("token")
  if(file && filename && root) {
    body = createFormData(root, filename, file, body)
  }
  else {
    body = JSON.stringify(body)
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': (file && filename && root) ? 'multipart/form-data' : 'application/json',
    'Authorization': `Bearer ${token}`
  }

  return authRequest(url, body, 'POST', headers);
}

const authPatchRequest = async (url, body={}) => {
  const token = await retrieveData("token")
  body = JSON.stringify(body)

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }

  return authRequest(
    authDeleteRequest,url, body, 'PATCH', headers);
}

const authGetRequest = async (url) => {
  const token = await retrieveData("token")
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  return authRequest(url, false, 'GET', headers);
}

const authDeleteRequest = async (url) => {
  const token = await retrieveData("token")
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  return authRequest(url, false, 'DELETE', headers);
}

const authRequest = async (url, body, method, headers) => {
  const req =  {
    method: method,
    headers: headers
  }
  if(body){
    req.body = body
  }

  return fetch(url, req)
  .then((response) => {
    if([400, 401,403].includes(response.status)){
      alert("Credenciais não são válidas, faça login novamente.");
      clear();
      return false
    }
    else {
      return response.json()
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

const createFormData = (root, filename, file, body) => {
  const data = new FormData();

  data.append(`${root}[${filename}]`, {
    name: file.uri.split(/(([^/](?!\/))+$)/)[1],
    type: 'image/jpeg',
    uri: file.uri
  });

  Object.keys(body).forEach(key => {
    data.append(`${root}[${key}]`, body[key]);
  });

  return data;
};

export {
  URL,
  login,
  request,
  authDeleteRequest,
  authPatchRequest,
  authPostRequest,
  authGetRequest,
}