import React, { useState, useEffect } from 'react';
import { URL, authGetRequest, authPostRequest } from '../helpers/API';

import Pet from './pet';

import Header from '../components/header';
import BigCard from '../components/bigCard';
import PetWidget from '../components/petWdiget';

import {
  Switch,
  Route
} from "react-router-dom";

function Pets({updatePage}) {
  const [pets, setPets] = useState();
  const [shownPets, setShownPets] = useState();
  const [loading, setLoading] = useState(true);

  const loadData = () => {
    return authGetRequest(`${URL}/admin/pets`)
      .then((json) => {
        setPets(json);
        setShownPets(json);
        setLoading(false);
      })
  }

  useEffect(() => {
    updatePage('pets');
    if(!pets){
      loadData()
    }
  });

  const renderPets = () => {
    if(loading) {
      return (
        <tr>
          <td class="px-6 py-4 whitespace-nowrap">
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="loader">Loading...</div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
          </td>
        </tr>
      )
    } else {
      return shownPets.map((p) => {
        if(p.collar){
          return (
            <PetWidget
              users={p.users}
              pet={p.pet}
              charge={p.charge}
              collar={p.collar}
            />
          )
        }
        return false
      })
    }
  }

  // const sendBatteryNotification = (id) => {
  //   authPostRequest(
  //     `${URL}/admin/notifications`,
  //     {
  //       user_id: id,
  //       title: "Carregar Bateria",
  //       message: "Não se esqueça de carregar sua Urdog, bateria descarregada."
  //     }
  //   ).then((res) => console.log(res))
  // }

  return (
    <Switch>
      <Route path={`/:petId`}>
        <Pet updatePage={updatePage} pets={pets}/>
      </Route>
      <Route path='/'>
        <Header title="Pets"/>
        <BigCard>
          <div class='w-full h-12'>
            <input 
              type='text' 
              class="p-3 w-full h-12 border-2 border-gray-200 shadow-lg rounded-lg"
              placeholder="Pesquisar"
              onChange={(e) => {
                const search = e.target.value
                loadData().then(() => {
                  if(search != "" || search !== undefined || search !== null) {
                    console.log(search)
                    console.log(pets)
                    const results = pets.filter((p) => {
                      return(
                        p.pet.name.includes(search) ||
                        p.collar?.identifier?.includes(search) ||
                        p.users.some((u) => u.name.includes(search))
                      )
                    })
                    setShownPets(results)
                  }
                })
              }}
            />
          </div>
          <div class="flex flex-row w-full flex-wrap items-center justify-center">
           {renderPets()}
          </div>
        </BigCard>
      </Route>
    </Switch>
  );
}

export default Pets;
