import React from 'react';

import { Link } from "react-router-dom";
import moment from 'moment'
import BatteryDisplay from './batteryDisplay';

function PetWidget({users, pet, collar, charge}) {
  const startTime = moment(collar.last_synced_at);
  const duration = moment.duration(moment().diff(startTime));
  const hours = duration.asHours();

  const last_charge = (charge) ? charge.finished_at : null 

  const renderOwners = () => {
    return users.map((u) => {
      return (
        <div class="text-xs mb-1 mr-1 py-1 px-2 bg-gray-200 rounded-lg">{u.name.split(" ")[0]}</div>
      )
    })
  }

  return (
    <Link to={`/${pet.id}`}>
      <div class="w-48 m-5 p-5 shadow-lg rounded-xl flex flex-col items-start">
        <img class="rounded-full shadow-md border-2 border-white" src={pet.avatar.url} />
        <p class="text-lg mt-5 mb-2 font-bold">{pet.name}</p>
        <hr/>
        <div class="text-xs text-gray-500">{collar.identifier}</div>
        <div class="text-xs text-gray-500 mb-1">
          <span class={`inline-flex text-xs ${hours > 2 ? 'text-red-500' : ''}`}>
            <small>{moment(collar.last_synced_at).fromNow()}</small>
          </span>
        </div>
        <div>
          <BatteryDisplay collar={collar}/>
          <span class={`text-xs ${hours > 2 ? 'text-red-500' : ''}`}>
            <small>
              {last_charge ? `carregado ${moment(last_charge).fromNow()}` : ''}
              <br />
              ({last_charge ? moment(last_charge).format('DD/MM - HH:mm') : ''})
            </small>
          </span>
        </div>
        <p class="mt-5 text-xs font-semibold"><small>Donos:</small></p>
        <div class="flex flex-wrap">
          {renderOwners()}
        </div>
      </div>
    </Link>
  );
}

export default PetWidget;
