import React, { useState } from 'react';

function UpdateCollarForm({loading, userData}) {
  const [success, setSuccess] = useState(false);
  const [frequency, setFrequency] = useState(9);
  const [firmware, setFirmware] = useState('');
  const options = [3,6,9,12,15,18,21,24,27,30];
  const handleSubmit = (event) => {
    event.preventDefault();
    var mqtt = require('mqtt')
    var client  = mqtt.connect(
      'ws://rabbitmq.urdog.com.br:15675/ws',
      {
        clientId: `urdogadmin${userData.collar.identifier}`,
        username: 'urdogadmin',
        password: '8LQXjpncFQwEf*W',
        clean: true,
        reconnectPeriod: 0,
        protocolId: 'MQIsdp',
        protocolVersion: 3
      }
    )

    client.on('connect', function () {
      const message = `${frequency},0,0`
      client.publish(
        `collar/${userData.collar.identifier}/config`,
        message,
        {},
        () => {
          setSuccess(true);
          client.end()
          setTimeout(() => {
            setSuccess(false)
          }, 1000)
        }
      )
    })
  }

  if(loading) {
    return (
      <div class="w-1/2 mt-5">
        <div class="bg-white shadow sm:rounded-lg p-5">
          <div class="loader">Loading...</div>
        </div>
      </div>
    )
  }

  return (
    <div class="w-1/2 mt-5 pr-2.5">
      <div
        class={`${(success) ? 'success items-center bg-green-500 animate-pulse' : ''} bg-white shadow rounded-lg overflow-hidden sm:rounded-lg p-5 flex flex-col justify-center`}
      >
        {(() => {
          if(success) {
            return (
              <div class="rounded-full border-4 border-white p-5 absolute">
                <svg fill="white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
              </div>
            )
          }
        })()}
        <h2 class="font-medium border-b-2 pb-2">Atualizar configuração da coleira</h2>
        <form onSubmit={handleSubmit} class="mt-5 flex items-end" action="#">
          <div class="mr-5">
            <label for="frequency" class="block text-sm font-medium text-gray-700">Frequência Sync (min)</label>
            <select
              id="frequency"
              name="frequency"
              autocomplete="frequency"
              onChange={(event) => setFrequency(event.target.value)}
              class="w-full mt-2 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
            >
              {
                (() => {
                  return options.map((option) => {
                    return <option selected={option === frequency} >{option}</option>
                  })
                })()
              }
            </select>
          </div>

          <div>
            <label for="firmware" class="block text-sm font-medium text-gray-700">Firmware</label>
            <input
              value={firmware}
              onChange={(event) => setFirmware(event.target.value)}
              type="text"
              disabled={true}
              name="firmware_version"
              id="firmware_version"
              class="w-full mt-2 block py-2 px-3 border border-gray-300 bg-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
            />
          </div>

          <div>
            <button type="submit" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
              Atualizar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateCollarForm;
