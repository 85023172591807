import React, { useState, useEffect } from 'react';
import { URL, authGetRequest } from '../helpers/API';

import {
  useParams,
} from "react-router-dom";

import Header from '../components/header';
import BigCard from '../components/bigCard';
import UserCard from '../components/userCard';
// import UserRealTimeData from '../components/userRealTimeData';
import LogsCard from '../components/logsCard';
import DebugOption from '../components/debugOption';
import LocationCard from '../components/locationCard';
import UpdateCollarForm from '../components/updateCollarForm';
import SendNotificationForm from '../components/sendNotificationForm';
import SendDataNotificationForm from '../components/sendDataNotificationForm';

function Pet({updatePage, pets}) {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const { petId } = useParams();

  useEffect(() => {
    updatePage('pet');
    if(!userData && loading){
      loadData();
    }
  });

  const loadData = () => {
    setLoading(true);
    let pet = pets.filter((p) => p.pet.id == petId)[0]
    setUserData(pet)
    setLoading(false);
  }

  const renderLocation = () => {
    if(userData?.last_location) {
      return (<LocationCard loading={loading} userData={userData}/>)
    }
  }

  return (
    <>
      <Header title="Usuários"/>
      <BigCard>
        <UserCard loading={loading} userData={userData} loadData={loadData}/>
        <UpdateCollarForm  loading={loading} userData={userData}/>
        <SendNotificationForm loading={loading} userData={userData}/>
        <SendDataNotificationForm loading={loading} userData={userData}/>
        <DebugOption loading={loading} userData={userData}/>
        {renderLocation()}
        <LogsCard loading={loading} userData={userData}/>
      </BigCard>
    </>
  );
}

export default Pet;
